import React, { Component, Suspense } from 'react'
import { HashRouter } from 'react-router-dom'
import './scss/style.scss'
import AllRoutes from './routes/AllRoutes'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <AllRoutes />
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
